var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"managequestions innerpage"},[_c('Header'),(!_vm.isLoading)?_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.pageTitle))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Edit "+_vm._s(_vm.pageTitle))]),_c('v-card-title',{staticClass:"px-0"},[_c('v-container',{staticClass:"fluid d-lg-flex align-center"},[_c('v-spacer'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-btn',{staticClass:"ml-auto mr-3",attrs:{"color":"secondary","dark":"","rounded":""},on:{"click":_vm.addQuestion}},[_vm._v(" + Add Question ")]),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"filled":"","label":"Question*","rules":[function (v) { return !!v || 'Content is required'; }],"required":""},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}}),_c('v-select',{attrs:{"items":_vm.categories,"rules":[function (v) { return !!v || 'Content is required'; }],"label":"Category*","item-text":"name","item-value":"id","outlined":"","clearable":""},model:{value:(_vm.editedItem.category_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "category_id", $$v)},expression:"editedItem.category_id"}}),_c('v-text-field',{attrs:{"filled":"","label":"Info Box"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_vm._v(" I'm a tooltip ")])]},proxy:true}],null,false,2155140339),model:{value:(_vm.editedItem.help_text),callback:function ($$v) {_vm.$set(_vm.editedItem, "help_text", $$v)},expression:"editedItem.help_text"}}),_c('v-subheader',[_vm._v("Explanation:")]),_c('TiptapVuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.editedItem.help_html),callback:function ($$v) {_vm.$set(_vm.editedItem, "help_html", $$v)},expression:"editedItem.help_html"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-subheader',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,1911353452)},[_c('span',[_vm._v("I'am a tooltip")])]),_vm._v(" Response Type*: ")],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}},[_c('v-radio',{attrs:{"label":"Multiple Choice","value":"checkbox","disabled":_vm.editedItem.category_id ? false : true}}),_c('v-radio',{attrs:{"label":"Text","value":"text"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.editedItem.type === 'checkbox')?_c('v-simple-table',{attrs:{"width":"90%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',{staticClass:"text-left"},[_vm._v(" Responses ")]),_c('th',{attrs:{"width":"5%"}})])]),_c('tbody',_vm._l((_vm.editedResponses),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":'Response ' +
                                                  (index + 1) +
                                                  '*  ',"rules":_vm.rules.response,"hide-details":"auto"},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Explanation","hide-details":"auto"},model:{value:(item.explanation),callback:function ($$v) {_vm.$set(item, "explanation", $$v)},expression:"item.explanation"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Score*","rules":_vm.rules.risk_score,"hide-details":"auto"},model:{value:(item.risk_score),callback:function ($$v) {_vm.$set(item, "risk_score", $$v)},expression:"item.risk_score"}})],1)],1),(_vm.editedItem.category_id > 0)?_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-checkbox',{key:index,attrs:{"label":"Add a conditional question to response"},on:{"change":function($event){return _vm.setHasCondQuestion(
                                                    item,
                                                    _vm.editedItem
                                                  )}},model:{value:(item.hasCondition),callback:function ($$v) {_vm.$set(item, "hasCondition", $$v)},expression:"item.hasCondition"}}),_vm._v(" If the above response is choosen, the following child question will be prompted after the parent"),_c('br'),_vm._v(" question is completed. Child questions must be added to the question banks to apppear in the"),_c('br'),_vm._v("drop down below. ")],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-expansion-panels',{attrs:{"multiple":"","disabled":item.hasCondition
                                                  ? false
                                                  : true},model:{value:(_vm.expandPanel[index]),callback:function ($$v) {_vm.$set(_vm.expandPanel, index, $$v)},expression:"expandPanel[index]"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Select Conditional Question ")]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Search","append-icon":"mdi-magnify","clearable":""},model:{value:(
                                                            _vm.condQuestionSearch
                                                          ),callback:function ($$v) {
                                                            _vm.condQuestionSearch
                                                          =$$v},expression:"\n                                                            condQuestionSearch\n                                                          "}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-5 mb-15 align-center",attrs:{"loading":_vm.condDataLoading,"loading-text":"Loading... Please wait","item-key":"id","headers":_vm.condQuestionsHeaders,"items":_vm.condItems,"options":_vm.condOptions,"server-items-length":_vm.condItemsTotal,"single-select":true,"show-select":"","footer-props":{
                                                            itemsPerPage: [10]
                                                          }},on:{"update:options":function($event){_vm.condOptions=$event}},model:{value:(
                                                            _vm.selectedCondQuestions
                                                          ),callback:function ($$v) {
                                                            _vm.selectedCondQuestions
                                                          =$$v},expression:"\n                                                            selectedCondQuestions\n                                                          "}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"ms-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectCondQuestion(
                                                              item,
                                                              index
                                                            )}}},[_vm._v(" SELECT CONDITIONAL QUESTION ")])],1)],1)],1)],1)],1)],1),(item.hasCondition)?_c('div',{staticClass:"mt-5"},[_c('strong',[_vm._v(_vm._s(_vm.printSelected( item.condQuestion .condition_question .content )))])]):_vm._e()],1)],1)],1)],1),_c('td',[_c('v-btn',{staticClass:"my-1",attrs:{"color":"secondary","rounded":""},on:{"click":function($event){return _vm.addRow(index)}}},[_vm._v(" Add Response ")]),_c('br'),_c('v-btn',{staticClass:"mb-5 mt-1",attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.removeRow(index)}}},[_vm._v(" Remove Response ")])],1)])}),0)]},proxy:true}],null,false,1360576931)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"back-to-blue mb-3 ml-5",attrs:{"rounded":""},on:{"click":function($event){_vm.addEditDialog = false}}},[_vm._v(" cancel ")]),_c('v-btn',{staticClass:"mb-3 mr-3 ms-auto",attrs:{"color":"secondary","rounded":""},on:{"click":function($event){return _vm.saveItem(_vm.editedItem)}}},[_vm._v(" save ")])],1)],1)],1)],1)],1)],1),_c('v-card-text',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"type":"text","label":"Filter","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.categories,"label":"Category","item-text":"name","item-value":"id","outlined":"","clearable":""},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}})],1),_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":true,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"sort-desc":[false, true],"multi-sort":"","footer-props":{
                  itemsPerPage: [10]
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"pt-3"},[_vm._v(_vm._s(item.content))])]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"pt-3"},[_vm._v(_vm._s(item.type))])]}},{key:"item.category",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"pt-3"},[_vm._v(_vm._s(item.category))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pa-2",on:{"click":function($event){return _vm.displayItem(item)}}},on),[_vm._v("mdi-file-find")])]}}],null,true)},[_c('span',[_vm._v("Preview")])]),_c('v-icon',{staticClass:"pa-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-playlist-edit")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,false,3936959588),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}],null,false,508673173),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-form',[_c('v-card',[_c('v-card-title',[_vm._v(" Question Preview ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('div',{staticClass:"subtitle-1",domProps:{"innerHTML":_vm._s(_vm.previewItem.content)}}),(_vm.previewItem.type === 'text')?[_c('v-text-field',{attrs:{"label":"Answer","placeholder":""}})]:_vm._l((_vm.previewResponses),function(item){return _c('div',{key:item.id},[_c('QuizResponse',{attrs:{"item":item},on:{"clicked":_vm.onClickResponse}})],1)})],2),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.previewDialog = false}}},[_vm._v(" Close ")]),(_vm.previewNextBtn)?_c('v-btn',{staticClass:"primary"},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading new data")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }