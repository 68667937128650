<template slot="body">
  <div>
    <v-btn class="my-1 mybutton" rounded depressed @click="onClickButton(item.id)">
      {{ item.content }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Response",
  props: {
    item: {}
  },
  methods: {
    onClickButton(itemId) {
      this.$emit('clicked', itemId);
    }
  }
};
</script>
<style scoped>
.mybutton {
  text-transform: none !important;
}
</style>
