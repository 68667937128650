<template>
  <div class="managequestions innerpage">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col md="12">
          <h1>{{ pageTitle }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Edit {{ pageTitle }}</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-btn
                    color="secondary"
                    class="ml-auto mr-3"
                    dark
                    rounded
                    @click="addQuestion"
                  >
                    + Add Question
                  </v-btn>
                  <v-dialog v-model="addEditDialog" width="1000">
                    <v-card>
                      <v-card-title>{{ formTitle }}</v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-text-field
                                filled
                                v-model="editedItem.content"
                                label="Question*"
                                :rules="[v => !!v || 'Content is required']"
                                required
                              ></v-text-field>
                              <v-select
                                v-model="editedItem.category_id"
                                :items="categories"
                                :rules="[v => !!v || 'Content is required']"
                                label="Category*"
                                item-text="name"
                                item-value="id"
                                outlined
                                clearable
                              ></v-select>
                              <v-text-field
                                filled
                                label="Info Box"
                                v-model="editedItem.help_text"
                              >
                                <template v-slot:append>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    I'm a tooltip
                                  </v-tooltip>
                                </template>
                              </v-text-field>

                              <v-subheader>Explanation:</v-subheader>
                              <TiptapVuetify
                                v-model="editedItem.help_html"
                                :extensions="extensions"
                              >
                              </TiptapVuetify>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-subheader>
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-icon v-on="on" class="mr-2"
                                      >mdi-help-circle-outline</v-icon
                                    >
                                  </template>
                                  <span>I'am a tooltip</span>
                                </v-tooltip>
                                Response Type*:
                              </v-subheader>
                              <v-radio-group v-model="editedItem.type" row>
                                <v-radio
                                  label="Multiple Choice"
                                  value="checkbox"
                                  :disabled="
                                    editedItem.category_id ? false : true
                                  "
                                ></v-radio>
                                <v-radio label="Text" value="text"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-simple-table
                                v-if="editedItem.type === 'checkbox'"
                                width="90%"
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th class="text-left">
                                        Responses
                                      </th>
                                      <th width="5%"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in editedResponses"
                                      :key="index"
                                    >
                                      <td vertical-align="middle">
                                        {{ index + 1 }}
                                      </td>
                                      <td>
                                        <v-container>
                                          <v-row>
                                            <v-col>
                                              <v-text-field
                                                :label="
                                                  'Response ' +
                                                    (index + 1) +
                                                    '*  '
                                                "
                                                :rules="rules.response"
                                                hide-details="auto"
                                                v-model="item.content"
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="10">
                                              <v-text-field
                                                label="Explanation"
                                                hide-details="auto"
                                                v-model="item.explanation"
                                              ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                              <v-text-field
                                                label="Score*"
                                                :rules="rules.risk_score"
                                                hide-details="auto"
                                                v-model="item.risk_score"
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                          <v-row
                                            v-if="editedItem.category_id > 0"
                                          >
                                            <v-col>
                                              <v-container class="px-0" fluid>
                                                <v-checkbox
                                                  :key="index"
                                                  v-model="item.hasCondition"
                                                  label="Add a conditional question to response"
                                                  @change="
                                                    setHasCondQuestion(
                                                      item,
                                                      editedItem
                                                    )
                                                  "
                                                ></v-checkbox>
                                                If the above response is
                                                choosen, the following child
                                                question will be prompted after
                                                the parent<br />
                                                question is completed. Child
                                                questions must be added to the
                                                question banks to apppear in
                                                the<br />drop down below.
                                              </v-container>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col>
                                              <v-expansion-panels
                                                v-model="expandPanel[index]"
                                                multiple
                                                :disabled="
                                                  item.hasCondition
                                                    ? false
                                                    : true
                                                "
                                              >
                                                <v-expansion-panel>
                                                  <v-expansion-panel-header>
                                                    Select Conditional Question
                                                  </v-expansion-panel-header>
                                                  <v-expansion-panel-content>
                                                    <v-container>
                                                      <v-row>
                                                        <v-col>
                                                          <v-text-field
                                                            v-model="
                                                              condQuestionSearch
                                                            "
                                                            type="text"
                                                            label="Search"
                                                            append-icon="mdi-magnify"
                                                            clearable
                                                          ></v-text-field>
                                                        </v-col>
                                                      </v-row>
                                                      <v-row>
                                                        <v-col>
                                                          <v-data-table
                                                            v-model="
                                                              selectedCondQuestions
                                                            "
                                                            :loading="
                                                              condDataLoading
                                                            "
                                                            loading-text="Loading... Please wait"
                                                            item-key="id"
                                                            :headers="
                                                              condQuestionsHeaders
                                                            "
                                                            :items="condItems"
                                                            :options.sync="
                                                              condOptions
                                                            "
                                                            :server-items-length="
                                                              condItemsTotal
                                                            "
                                                            class="elevation-5 mb-15 align-center"
                                                            :single-select="
                                                              true
                                                            "
                                                            show-select
                                                            :footer-props="{
                                                              itemsPerPage: [10]
                                                            }"
                                                          >
                                                          </v-data-table>
                                                        </v-col>
                                                      </v-row>
                                                      <v-row>
                                                        <v-col>
                                                          <v-btn
                                                            color="primary"
                                                            class="ms-auto"
                                                            @click="
                                                              selectCondQuestion(
                                                                item,
                                                                index
                                                              )
                                                            "
                                                          >
                                                            SELECT CONDITIONAL
                                                            QUESTION
                                                          </v-btn>
                                                        </v-col>
                                                      </v-row>
                                                    </v-container>
                                                  </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>

                                              <div
                                                v-if="item.hasCondition"
                                                class="mt-5"
                                              >
                                                <strong>{{
                                                  printSelected(
                                                    item.condQuestion
                                                      .condition_question
                                                      .content
                                                  )
                                                }}</strong>
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </td>
                                      <td>
                                        <v-btn
                                          color="secondary"
                                          class="my-1"
                                          rounded
                                          @click="addRow(index)"
                                        >
                                          Add Response </v-btn
                                        ><br />
                                        <v-btn
                                          color="primary"
                                          rounded
                                          class="mb-5 mt-1"
                                          @click="removeRow(index)"
                                        >
                                          Remove Response
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          class="back-to-blue mb-3 ml-5"
                          rounded
                          @click="addEditDialog = false"
                        >
                          cancel
                        </v-btn>
                        <v-btn
                          class="mb-3 mr-3 ms-auto"
                          color="secondary"
                          rounded
                          @click="saveItem(editedItem)"
                        >
                          save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-form>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="keywords"
                    type="text"
                    label="Filter"
                    append-icon="mdi-magnify"
                    clearable
                  ></v-text-field>

                  <v-spacer></v-spacer>
                  <v-select
                    v-model="category_id"
                    :items="categories"
                    label="Category"
                    item-text="name"
                    item-value="id"
                    outlined
                    clearable
                  ></v-select>
                </v-card-title>
                <v-data-table
                  v-model="items"
                  :loading="dataLoading"
                  loading-text="Loading... Please wait"
                  item-key="id"
                  :disable-sort="true"
                  :headers="headers"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="itemsTotal"
                  :sort-desc="[false, true]"
                  multi-sort
                  class="elevation-1 mytable"
                  :footer-props="{
                    itemsPerPage: [10]
                  }"
                >
                  <template v-slot:item.content="{ item }">
                    <p class="pt-3">{{ item.content }}</p>
                  </template>
                  <template v-slot:item.type="{ item }">
                    <p class="pt-3">{{ item.type }}</p>
                  </template>
                  <template v-slot:item.category="{ item }">
                    <p class="pt-3">{{ item.category }}</p>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <span class="group">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            class="pa-2"
                            v-on="on"
                            @click="displayItem(item)"
                            >mdi-file-find</v-icon
                          >
                        </template>
                        <span>Preview</span>
                      </v-tooltip>

                      <v-icon class="pa-2" @click="editItem(item)"
                        >mdi-playlist-edit</v-icon
                      >
                      <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
          <v-dialog v-model="previewDialog" max-width="500px">
            <v-form>
              <v-card>
                <v-card-title>
                  Question Preview
                </v-card-title>
                <v-card-text class="pt-5">
                  <div class="subtitle-1" v-html="previewItem.content"></div>
                  <template v-if="previewItem.type === 'text'">
                    <v-text-field label="Answer" placeholder=""></v-text-field>
                  </template>
                  <template v-else>
                    <div v-for="item in previewResponses" :key="item.id">
                      <QuizResponse
                        :item="item"
                        @clicked="onClickResponse"
                      ></QuizResponse>
                    </div>
                  </template>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="previewDialog = false">
                    Close
                  </v-btn>
                  <v-btn v-if="previewNextBtn" class="primary">
                    Next
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import QuizResponse from "@/components/QuizResponse";
import LicenseService from "@/services/license.service";
import CoursesService from "../services/courses.service";
import Sortable from "sortablejs";
import { Role } from "@/helpers/roles";
//import Tiptap from "@/components/TipTap";
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "Generic",
  components: {
    TiptapVuetify,
    QuizResponse,
    Header
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      addEditDialog: false,
      previewDialog: false,
      previewNextBtn: false,
      // selectedCategory: {
      //   id: "",
      //   name: ""
      // },
      keywords: "",
      category_id: null,

      condOptions: {},
      condItemsTotal: 0,
      condCurrentPage: 1,
      condLastPage: null,

      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,

      originalItem: {
        content: "",
        help_html: "",
        help_text: "",
        type: "",
        category: "",
        category_id: null,
        conditions: []
      },
      defaultItem: {
        content: "",
        help_html: "",
        help_text: "",
        type: "",
        category: "",
        category_id: null,
        conditions: []
      },
      editedItem: {
        content: "",
        help_html: "",
        help_text: "",
        type: "text",
        category: "",
        category_id: null,
        conditions: []
      },
      previewItem: {
        content: "",
        help_html: "",
        help_text: "",
        type: "text",
        category: "",
        category_id: null,
        conditions: []
      },
      originalResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // hasControlQuestion: false,
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      defaultResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      editedResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // hasControlQuestion: false,
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      emptyResponse: {
        id: "",
        question_id: "",
        content: "",
        risk_score: "",
        explanation: null,
        condition: null,
        hasCondition: false
        // hasControlQuestion: false,
        // condQuestion: {
        //   condition_question_content: ""
        // }
      },
      previewResponses: [
        {
          id: "",
          question_id: "",
          content: "",
          risk_score: "",
          explanation: null,
          condition: null,
          hasCondition: false
          // hasControlQuestion: false,
          // condQuestion: {
          //   condition_question_content: ""
          // }
        }
      ],
      condCatId: null,
      categories: [],
      rules: {
        response: [
          value => !!value || "Required.",
          value => (value && value.length >= 2) || "Min 3 characters"
        ],
        risk_score: [value => !!value || "Required."]
      },
      condItems: [],
      condDataLoading: false,
      items: [],
      questionsSelected: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      consReports: [],
      orgReports: [],
      expanded: [],
      quiz: {},
      headers: [
        { text: "Question", value: "content" },
        { text: "Type", value: "type" },
        { text: "Category", value: "category" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      condQuestionSearch: null,
      expandPanel: [],
      selectedConds: [],
      selectedCondQuestions: [],
      condQuestionsHeaders: [
        { text: "Content", value: "content", width: "60%" },
        { text: "Type", value: "type" }
      ],
      masterQuestionsModal: false,
      editedIndex: -1
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    pagination: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedQuestions();
        }
      },
      deep: true
    },

    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getPagedItems();
        }
      },
      deep: true
    },

    keywords: {
      async handler() {
        if (this.keywords) this.searchItems();
        else if (this.category_id) this.searchItemsByCategory();
        else this.getPagedItems();
      },
      deep: true
    },

    category_id: {
      async handler() {
        if (this.category_id && !this.keywords) this.searchItemsByCategory();
        else if (this.keywords) this.searchItems();
        else this.getPagedItems();
      },
      deep: true
    },

    condOptions: {
      async handler() {
        if (this.lastPage !== null && this.condCatId) {
          this.setCondItems();
        }
      },
      deep: true
    }
  },

  computed: {
    pageTitle() {
      if (this.isAdminEditor) return "Master Question Bank";
      else return "Custom Question Bank";
    },

    formTitle() {
      return this.editedIndex === -1 ? "Create New Question" : "Edit Question";
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    custom() {
      return this.isAdminEditor ? 0 : 1;
    },

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },
  directives: {
    sortableDataTable: {
      bind() {
        // bind(el, binding, vnode) {
        // const options = {
        //   animation: 150,
        //   onUpdate: function(event) {
        //     vnode.child.$emit("sorted", event);
        //   }
        // };
        // Sortable.create(el.getElementsByTagName("tbody")[0], options);
        var that = this;

        var options = {
          draggable: Object.keys(this.modifiers)[0]
        };

        this.sortable = Sortable.create(this.el, options);

        this.sortable.option("onUpdate", function(e) {
          that.value.splice(e.newIndex, 0, that.value.splice(e.oldIndex, 1)[0]);
        });

        this.onUpdate = function(value) {
          that.value = value;
        };
      },

      update(value) {
        this.onUpdate(value);
      }
    }
  },

  methods: {
    onClickResponse(value) {
      let condition = this.previewItem.conditions.find(
        item => item.condition_response_id == value
      );
      if (condition)
        this.previewItem = Object.assign({}, condition.condition_question);
    },

    addQuestion() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.editedResponses = this.defaultResponses.slice();
      this.addEditDialog = true;
      this.$refs.form.reset();
    },

    displayHelp() {
      console.log("help box");
    },

    displayItem(item) {
      this.previewDialog = true;
      this.previewItem = Object.assign({}, item);
      this.previewResponses = item.responses;
      if (item.conditions.length > 0) this.previewNextBtn = true;
      else this.previewNextBtn = false;
    },

    editItem(item) {
      this.condCatId = null;
      this.originalItem = Object.assign({}, item);
      // get question
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (item.type === "checkbox") {
        //this.setResponses(this.editedItem.id);
        this.editedResponses = item.responses;
        this.editedResponses.forEach(response => {
          let condQuestion = item.conditions.find(
            val => val.condition_response_id === response.id
          );
          if (condQuestion) {
            response.condQuestion = condQuestion;
            response.hasCondition = true;
          } else {
            response.condQuestion = null;
          }
        });
      } else {
        this.editedResponses = this.defaultResponses.slice();
      }
      this.addEditDialog = true;
    },

    showNestedDialog(type) {
      if (type === "custom") this.addCustomDialog = true;
      else if (type === "master") this.masterQuestionsModal = true;
      this.addEditDialog = false;
    },
    addRow(index) {
      const empty = Object.assign({}, this.emptyResponse);
      try {
        this.editedResponses.splice(index + 1, 0, empty);
      } catch (e) {
        console.log(e);
      }
    },
    removeRow(index) {
      this.editedResponses.splice(index, 1);
    },
    saveOrder(event) {
      const movedItem = this.quizQuestions.splice(event.oldIndex, 1)[0];
      this.quizQuestions.splice(event.newIndex, 0, movedItem);
    },
    // deleteQuestions() {
    //   this.showQuestionsDeletedStatus = true;
    //   this.deleteQuestionsModal = false;
    //   console.log("Questions will be deleted");
    // },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.setCategoriesList();
      this.getPagedItems();
    },

    async getMyLicenses() {
      await LicenseService.getMyLicenses({});
    },

    async searchItemsByCategory() {
      this.dataLoading = true;
      const { page, itemsPerPage } = this.options;
      const response = await CoursesService.getQuestionsByCategory({
        id: this.category_id,
        query: {
          page: page,
          custom: this.custom
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.items = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      //log(this.items);

      this.setCategoryName();
      this.dataLoading = false;
    },

    async searchItems() {
      this.dataLoading = true;
      const { page, itemsPerPage } = this.options;
      let data;
      if (this.keywords && this.category_id) {
        data = {
          keywords: this.keywords,
          category_id: this.category_id,
          custom: this.custom
        };
      } else if (this.keywords) {
        data = {
          keywords: this.keywords,
          custom: this.custom
        };
      }

      const response = await CoursesService.getQuestionsSearch({
        data: data,
        query: {
          page: page
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.items = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.setCategoryName();
      this.dataLoading = false;
    },

    async getPagedItems() {
      this.dataLoading = true;
      const { page, itemsPerPage } = this.options;

      const response = await CoursesService.getQuestions({
        query: {
          page: page,
          custom: this.custom
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.items = response.data.data.slice();
      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.setCategoryName();
      this.dataLoading = false;
    },

    setCategoryName() {
      this.items.map(res => {
        res.category = this.categories.filter(
          cat => cat.id === res.category_id
        )[0];
        if (res.category) res.category = res.category.name;
      });
    },
    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        let data = {};
        let message = "The Question has been updated successfully";
        if (this.editedIndex > -1) {
          // delete item
          if (!this.isAdminEditor) {
            data = {
              custom: true,
              organization_id: this.getOrgId()
            };
          }
          //remove question
          await CoursesService.removeQuestion({
            id: item.id,
            data: data
          });
        } else {
          message = "The Question has been added successfully";
        }
        // 1. create question
        if (this.isAdminEditor) {
          data = {
            label: "label",
            content: item.content,
            help_html: item.help_html,
            help_text: item.help_text,
            type: item.type,
            category_id: item.category_id
          };
        } else {
          data = {
            label: "label",
            content: item.content,
            help_html: item.help_html,
            help_text: item.help_text,
            type: item.type,
            custom: true,
            organization_id: this.getOrgId(),
            category_id: item.category_id
          };
        }

        //log("data ", data);

        const response = await CoursesService.createQuestion({
          data: data
        });

        // 2. get question id
        const savedItem = response.data.data;
        const question_id = savedItem.id;

        if (item.type === "checkbox") {
          //create copy of responses array
          this.originalResponses = this.editedResponses.slice();

          //create data - array of responses
          const responsesData = [];

          this.editedResponses.forEach(item => {
            if (this.isAdminEditor) {
              responsesData.push({
                question_id: question_id,
                content: item.content,
                explanation: item.explanation,
                risk_score: item.risk_score
              });
            } else {
              responsesData.push({
                question_id: question_id,
                content: item.content,
                explanation: item.explanation,
                risk_score: item.risk_score,
                custom: true,
                organization_id: this.getOrgId()
              });
            }
          });

          // 3. create responses
          const newResponses = await CoursesService.createResponses({
            data: responsesData
          });

          // 4. get response ids
          this.editedResponses = newResponses.data.data;

          // prepare data for question_codition
          const question_conditionData = [];

          this.originalResponses.forEach((item, index) => {
            this.originalResponses[index].question_id = question_id;
            this.originalResponses[index].id = this.editedResponses[index].id;
            if (this.originalResponses[index].condQuestion != null) {
              if (this.originalResponses[index].condQuestion.question_id) {
                if (this.isAdminEditor) {
                  question_conditionData.push({
                    question_id: this.originalResponses[index].condQuestion
                      .question_id,
                    condition_question_id: question_id,
                    condition_response_id: this.editedResponses[index].id
                  });
                } else {
                  question_conditionData.push({
                    question_id: this.originalResponses[index].condQuestion
                      .question_id,
                    condition_question_id: question_id,
                    condition_response_id: this.editedResponses[index].id,
                    custom: true,
                    organization_id: this.getOrgId()
                  });
                }
              }
            }
          });

          if (question_conditionData.length > 0) {
            //5. create question_conditions
            await CoursesService.createQuestionConditions({
              data: question_conditionData
            });
          }

          //reset the editedResponses to default
          this.editedResponses = this.defaultResponses.slice();
        }

        const responseQuestion = await CoursesService.getQuestion({
          id: savedItem.id
        });

        let savedQuestion = responseQuestion.data.data;

        if (this.editedIndex > -1) {
          this.items.splice(this.editedIndex, 1, savedQuestion);
        } else {
          this.items.push(savedQuestion);
        }

        //set category name
        savedQuestion.category = this.categories.filter(
          cat => cat.id === savedQuestion.category_id
        )[0];
        if (savedQuestion.category)
          savedQuestion.category = savedQuestion.category.name;

        this.showSnackbar(message);
        this.addEditDialog = false;
        this.$refs.form.reset();
      } else {
        console.log("Data not valid");
      }
    },

    printSelected(question) {
      question = question.replace(/<\/?[^>]+(>|$)/g, "");
      if (question !== "") return "Selected: " + question;
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this question?") &&
        this.items.splice(index, 1);
      let data = null;
      if (!this.isAdminEditor) {
        data = {
          custom: true,
          organization_id: this.getOrgId()
        };
      }
      //remove question
      await CoursesService.removeQuestion({
        id: item.id,
        data: data
      });

      //checkbox type
      //if (item.type === "checkbox") {
      //remove responses by question Id
      // await CoursesService.removeResponses({
      //   id: item.id
      // });
      //}

      //remove question_conditions by question id

      this.showSnackbar(item.content + " has been deleted");
    },

    async getItemsByCategory() {
      this.dataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.selectedCategory,
        query: {
          page: page
        }
      });
      this.items = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      if (itemsPerPage > 0) {
        this.items = this.items.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.setCategoryName();
      this.dataLoading = false;
    },

    setHasCondQuestion(item, editedItem) {
      if (item.hasCondition === false) {
        item.condQuestion = null;
      } else {
        if (this.isAdminEditor) {
          item.condQuestion = {
            question_id: "",
            condition_question_id: "",
            condition_response_id: "",
            condition_question: { content: "" }
          };
        } else {
          item.condQuestion = {
            question_id: "",
            condition_question_id: "",
            condition_response_id: "",
            custom: true,
            organization_id: this.orgId,
            condition_question: { content: "" }
          };
        }
      }
      this.condCatId = editedItem.category_id;
      this.setCondItems();
    },

    async setCondItems() {
      this.condDataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.condOptions;

      const response = await CoursesService.getQuestionsByCategory({
        id: this.condCatId,
        query: {
          page: page
        }
      });

      this.condOptions.itemsPerPage = parseInt(response.data.meta.per_page);
      this.condItemsTotal = response.data.meta.total;
      this.condLastPage = response.data.meta.last_page;

      this.condItems = response.data.data.slice();

      if (itemsPerPage > 0) {
        this.condItems = this.condItems.slice(
          (this.condCurrentPage - 1) * itemsPerPage,
          this.condCurrentPage * itemsPerPage
        );
      }
      this.condDataLoading = false;
    },
    
    selectCondQuestion(response, index) {
      console.log("selectedCondQuestions", this.selectedCondQuestions);
      response.condQuestion.question_id = this.selectedCondQuestions[0].id;
      response.condQuestion.condition_question.content = this.selectedCondQuestions[0].content;
      //response.hello = response.condQuestion;
      console.log("response", response);
      this.selectedCondQuestions = [];
      this.expandPanel[index] = false;
    },
    async setCategoriesList() {
      try {
        const response = await CoursesService.getCategories();
        this.categories = response.data.data;
      } catch (e) {
        console.log("error getting categories", e);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-data-table td {
  white-space: nowrap;
}
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  padding-top: 20px !important;
}

.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
.resource-card {
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
